// Edit app properties on successpage.
(function ($, llprod) {
	if (typeof (llprod) === 'undefined') {
		llprod = {};
	}

	llprod.apppropertiesEdit = function () {
		var headline, headlineLimit, description, descriptionLimit, controlBar, menubarFormActionsWrapper, headlineBackup, descriptionBackup, submitButton, cancelButton, url, jqXHR, params, jsonString, appdd, self = this;
		headline = $('.mylivingapps-detail-app').find('.headline input');
		headlineLimit = $('.mylivingapps-detail-app').find('.headline-limit-wrapper');
		description = $('.mylivingapps-detail-app').find('.description textarea');
		descriptionLimit = $('.mylivingapps-detail-app').find('.description-limit-wrapper');
		controlBar = $('.control-bar');
		menubarFormActionsWrapper = $('.menubar-form-actions');
		submitButton = $('.menubar-form-actions-submit');
		cancelButton = $('.menubar-form-actions-cancel');

		if (headline && headline.length || description && description.length) {

			if (!headlineBackup) {
				headlineBackup = headline.val();
			}
			if (!descriptionBackup) {
				descriptionBackup = description.val();
			}

			headline.closest('form').bind("keyup keypress", function (e) {
				var code = e.keyCode || e.which;
				if (code == 13) {
					e.preventDefault();
					return false;
				}
			});

			headline.on('focus', function () {
				headlineLimit.fadeIn('fast');
				headline.unbind('keyup keypress').bind("keyup keypress", function (e) {
					var code = e.keyCode || e.which;
					if (code == 13) {
						e.preventDefault();
						headline.blur();
						appPropertiesSubmit();
						menubarFormActionsWrapper.fadeOut('fast');
						headlineLimit.fadeOut('fast');
						return false;
					}
					if (headlineBackup != headline.val()) {
						menubarFormActionsWrapper.fadeIn('fast');
					} else {
						menubarFormActionsWrapper.fadeOut('fast');
					}
				});
			});

			headline.on('blur', function () {
				headlineLimit.fadeOut('fast');
			});

			description.on('focus', function () {
				descriptionLimit.fadeIn('fast');
				description.unbind('keyup keypress').bind("keyup keypress", function (e) {
					if (descriptionBackup != description.val()) {
						menubarFormActionsWrapper.fadeIn('fast');
					} else {
						menubarFormActionsWrapper.fadeOut('fast');
					}
				});
			});

			description.on('blur', function () {
				descriptionLimit.fadeOut('fast');
			});

			submitButton.unbind('click').click(function (e) {
				e.preventDefault();
				if (headline.val().length) {
					appPropertiesSubmit();
					menubarFormActionsWrapper.fadeOut('fast');
					headlineLimit.fadeOut('fast');
				} else {
					headline.focus();
				}
			});

			cancelButton.unbind('click').click(function (e) {
				e.preventDefault();
				return_change();
				headline.trigger('change');
				description.trigger('autosize:update');
				menubarFormActionsWrapper.fadeOut('fast');
				headlineLimit.fadeOut('fast');
			});

			var return_change = function () {
				headline.val(headlineBackup);
				description.val(descriptionBackup);
			};

			var appPropertiesSubmit = function () {
				headlineBackup = headline.val();
				descriptionBackup = description.val();

				url = AjaxURLManager.getUrlWithKey('ajaxdetailitem');
				url += '/' + $('.mylivingapps-detail-app').attr('data-id') + '.htm';
				params = {
					'formFieldContentType': 'application/json',
					'formKey': $('.mylivingapps-detail-app').attr('data-id'),
					'p_tpl_id': $('.mylivingapps-detail-app').attr('data-id'),
					'p_tpl_name': headline.val(),
					'p_tpl_description': description.val(),
					'p_icon_list_upload_id': $("#p_icon_list_upload_id").val(),
					'cms.apps.input.SUBMIT': 'true',
				};
				$.blockUI({ message: '<img class="loading" src="/formbuilder/img/svg/loading-bubbles.svg" />' });
				jqXHR = $.post(url, params)
					.done(function (data, textStatus, jqXHR) {
						self.data = data;
						$.unblockUI();
						if (data) {
							llprod.notificationRowHandler('green', 'appbuilder_wizard_livingapp_detail_success_edit');
						} else {
							llprod.notificationRowHandler('red', 'appbuilder_wizard_livingapp_detail_error_edit');
						}
					});
			};
		}
	};
})(ll.$, window.llprod);
//<<

//>> copyPopupHandler.
(function ($, llprod) {
	if (typeof (llprod) === 'undefined') {
		llprod = {};
	}

	llprod.copyPopupHandler = function () {
		var popupCopy = $(".mylivingapps-popup-copy");
		if (popupCopy && popupCopy.length) {
			var openAccountsLink = $(".mylivingapps-popup-copy .open-accounts-link a");
			var accountsInputContainer = $(".mylivingapps-popup-copy .accounts-input");
			var accountsInputField = $(".mylivingapps-popup-copy .accounts-input input");

			openAccountsLink.on('click', function (e) {
				e.preventDefault();

				if (accountsInputContainer.hasClass('closed')) {
					accountsInputContainer.removeClass('closed').addClass('opened');
					$(this).children('.font-icon').removeClass('font-icon-right').addClass('font-icon-down');
					accountsInputContainer.slideDown();
				} else {
					accountsInputContainer.removeClass('opened').addClass('closed');
					$(this).children('.font-icon').removeClass('font-icon-down').addClass('font-icon-right');
					accountsInputContainer.slideUp();
				}

				e.stopPropagation();
			});

			var copyButtons = $(".mylivingapps-popup-copy .button")
			copyButtons.on('click', function (e) {
				e.preventDefault();
				var newHref = $(this).attr("href");

				if (accountsInputField.val()) {
					newHref += "&p_accounts=" + encodeURIComponent(accountsInputField.val());
				}
				window.location = newHref;
			});
		}
	}
})(ll.$, window.llprod);
//<<

//>> Handle internal fields table.
(function ($, llprod, Utils) {
	if (typeof (llprod) === 'undefined') {
		llprod = {};
	}

	llprod.dataManagementInternalFieldsHandling = $.extend(
		Utils.clone(LLObject),
		{
			init: function (opts) {
				var o = LLObject.create.call(this);
				o.defaults = {
					internalFieldsTable: $('#content').find('.internalFieldsTable'),
					internalFieldsRowDelete: $('#content').find('.internal-field-delete'),
					internalFieldsRowNew: $('#content').find('.internal-field-new-row')
				};
				if (typeof (opts) !== 'undefined') {
					$.extend(true, o.defaults, opts);
				}
				o.handle();
				return o;
			},
			handle: function () {
				var internalFieldsTable, internalFieldsRowDelete, internalFieldsRowNew;
				internalFieldsTable = this.getDefAttr('internalFieldsTable', null);
				internalFieldsRowDelete = this.getDefAttr('internalFieldsRowDelete', null);
				internalFieldsRowNew = this.getDefAttr('internalFieldsRowNew', null);
				if (internalFieldsTable && internalFieldsTable.length) {
					internalFieldsRowDelete.click(function (e) {
						e.preventDefault();

						var $this = $(this).parents('tr');
						$this.fadeOut(400, function () {
							$(this).find('input').val('');
							$(this).parents('form').trigger('change');
							$this.remove();
						});
					});
					internalFieldsRowNew.bind('keyup', function () {
						var $this = $(this), $next = $this.nextAll(internalFieldsRowNew).eq(0);

						if ($.trim($this.find('input').val()) !== '' && $next.hasClass('hide')) {
							$next.fadeIn();
						}
					});
				}
			}
		}
	);
})(ll.$, window.llprod, window.Utils);
//<<


llprod.getAppddData = function () {
	var data = null, field = $('#p_tpl_appdd');
	if (field.length) {
		eval('data = ' + field.val());
	}
	return data;
};


llprod.setAppddData = function (data) {
	var field = $('#p_tpl_appdd');
	if (field.length) {
		field.val($.toJson(data));
	}
	return data;
};


llprod.catalogSearch = function () {
	var sortApps = $('.el_catalog-overview .sort select');
	var filterHandler = $('.catalog-overview-wrapper .filter');
	var filterInput = $('.el_catalog-overview #filterApps');
	if (sortApps.length) {
		sortApps.change(function () {
			$(this).closest('form').trigger('submit');
		});
	}
	if (filterHandler.length) {
		var filterInputVal = filterInput.val();
		filterHandler.on('click', 'li', function (event) {
			event.preventDefault();
			filterInput.val(event.target.id).closest('form').trigger('submit');
		});
		if (filterInputVal == 'filter-favorites') {
			$('#filter-favorites').addClass('active');
		} else {
			$('#filter-none').addClass('active');
		}
	}
};


llprod.catalogDetailPreview = function () {
	$('.catalog-detail-preview a').magnificPopup({
		removalDelay: 300,
		mainClass: 'mfp-fade',
		gallery: {
			enabled: true,
			tCounter: ''
		},
		callbacks: {
			change: function () {
				if (this.content.hasClass('video-popup')) {
					jwplayer().onReady(function () {
						var $width = $(".mediaplayerShell").width();
						var $height = $width / 1.5;
						jwplayer().resize($width, $height);
					});
				}
			}
		}
	});
};


llprod.mylivingappsSearch = function () {
	var sortApps = $('.el_mylivingapps-overview .sort select');
	var filterHandler = $('.mylivingapps-overview-wrapper .filter input:radio');
	var filterInput = $('.el_mylivingapps-overview #filterApps');
	var categoryHandler = $('.mylivingapps-overview-wrapper .category');
	var categoryInput = $('.el_mylivingapps-overview input[name="searchDescription2"]');
	if (sortApps.length) {
		sortApps.change(function () {
			$(this).closest('form').trigger('submit');
		});
	}
	if (filterHandler.length) {
		var filterInputVal = filterInput.val();
		var checked = true;
		filterHandler.change(function () {
			filterInput.val(this.value).closest('form').trigger('submit');
		});
		if (filterInputVal == 'filter-favorites') {
			$('input#filter-favorites').prop('checked', checked).next().toggleClass('checked', checked);
		} else if (filterInputVal == 'filter-published') {
			$('input#filter-published').prop('checked', checked).next().toggleClass('checked', checked);
		} else {
			$('input#filter-none').prop('checked', checked).next().toggleClass('checked', checked);
		}
	}
	if (categoryHandler.length) {
		var categoryInputVal = categoryInput.val();
		categoryHandler.on('click', 'li', function (event) {
			event.preventDefault();
			if (event.target.id == 'category-none') {
				categoryInput.val('').closest('form').trigger('submit');
			} else {
				categoryInput.val(event.target.id).closest('form').trigger('submit');
			}
		});
		if (categoryInputVal) {
			$('#' + categoryInputVal).parent('li').addClass('category__item--here');
		} else {
			$('#category-none').parent('li').addClass('category__item--here');
		}

		$('.category__item--here').parents('.has-subcategories').addClass('category__item--inPath');
	}
};


llprod.externalSystem = function () {
	$('.magnific-popup').magnificPopup({
		removalDelay: 300,
		mainClass: 'mfp-fade'
	});
	if ($('.external-login').length) {
		$('form').on('valid invalid submit', function (e) {
			e.stopPropagation();
			e.preventDefault();

			if (e.type === 'valid') {
				var url, formWrapper, params;

				formWrapper = $(e.target);
				url = AjaxURLManager.getUrlWithKey('account');
				url += '/' + formWrapper.find('#p_int_identifier').val();

				params = {
					'username': formWrapper.find('#p_username').val(),
					'password': formWrapper.find('#p_password').val(),
					'ide_id': formWrapper.find('#ide_id').val(),
					'lang': $.lang()
				};

				$.ajax({
					type: 'POST',
					url: url,
					contentType: "application/json",
					data: JSON.stringify(params),
					dataType: 'json',
					success: function (result) {
						if (result.status == 'error') {
							formWrapper.find('.head').append('<div class="error"><div class="abideError">' + result.message + '</div></div>');
						} else if (result.status == 'success') {
							window.location.reload();
						}
					}
				});
			}
		});
		$('.external-login-logout').on('click', function (e) {
			e.stopPropagation();
			e.preventDefault();

			var url, formWrapper, params;

			formWrapper = $(e.target).closest('.external-login');
			url = AjaxURLManager.getUrlWithKey('account');
			url += '/' + formWrapper.find('#p_int_identifier').val();

			params = {
				'ide_id': formWrapper.find('#ide_id').val(),
			};

			$.ajax({
				url: url + '?' + $.param(params),
				type: 'DELETE',
				contentType: "application/json",
				dataType: 'json',
				success: function (result) {
					window.location.reload();
				}
			});
		});
	}
};


llprod.contextmenu = function () {
	var contextmenuButton, contextmenuWrapper, contextmenuClose;

	var $win = $(window);
	contextmenuButton = $('.contextmenu-button');
	contextmenuClose = $('.contextmenu-close');
	contextmenuWrapper = $('.contextmenu');

	$win.on('click', function (e) {
		if (contextmenuWrapper.has(e.target).length === 0 && !contextmenuWrapper.is(e.target)) {
			contextmenuButton.removeClass('contextmenu-close').next('.contextmenu').hide();
			contextmenuButton.children('.font-icon').removeClass('font-icon-loeschen').addClass('font-icon-kontext');
		}
	});

	contextmenuButton.on('click', function (e) {
		$.each($('.contextmenu-close'), function () {
			$(this).removeClass('contextmenu-close').next('.contextmenu').hide();
			$(this).children('.font-icon').removeClass('font-icon-loeschen').addClass('font-icon-kontext');
		});
		if ($(this).hasClass('contextmenu-close')) {
			var contextmenuWrapperHeight = $(this).next('.contextmenu').outerHeight(true) + 10;
			$(this).removeClass('contextmenu-close').next('.contextmenu').hide();
			$(this).children('.font-icon').removeClass('font-icon-loeschen').addClass('font-icon-kontext');
		} else {
			$(this).addClass('contextmenu-close').next('.contextmenu').show();
			$(this).children('.font-icon').removeClass('font-icon-kontext').addClass('font-icon-loeschen');
		}
		e.stopPropagation();
	});

	contextmenuClose.on('click', function (e) {
		contextmenuButton.removeClass('contextmenu-close').next('.contextmenu').hide();
		contextmenuButton.children('.font-icon').removeClass('font-icon-loeschen').addClass('font-icon-kontext');
	});
};


llprod.usermenu = function () {
	var usermenuButton, usermenuDropdown, usermenuWrapper, usermenuClose;

	var $win = $(window);
	usermenuButton = $('.usermenu-profile-avatar-dropdown-button');
	usermenuClose = $('.usermenu-close');
	usermenuDropdown = $('.usermenu-profile-avatar-dropdown');
	usermenuWrapper = $('.usermenu');

	$win.on('click', function (e) {
		if (usermenuDropdown.has(e.target).length === 0 && !usermenuDropdown.is(e.target)) {
			usermenuButton.removeClass('usermenu-close').next('.usermenu-profile-avatar-dropdown').hide();
		}
	});

	usermenuButton.on('click', function (e) {
		if ($(this).hasClass('usermenu-close')) {
			$(this).removeClass('usermenu-close').next('.usermenu-profile-avatar-dropdown').hide();
		} else {
			$(this).addClass('usermenu-close').next('.usermenu-profile-avatar-dropdown').show();
		}
		e.stopPropagation();
	});
};


llprod.favoriteApp = function () {
	var favoriteButton, url, jqXHR, params;

	AjaxURLManager.registerKey('setfavorites', null, null, false, '/gateway/users/');

	favoriteButton = $('.favorite-button');

	if (favoriteButton.length) {
		favoriteButton.on('click', function (e) {
			e.preventDefault();

			url = AjaxURLManager.getUrlWithKey('setfavorites');
			if ($(e.target).parents('.panel').attr('data-favorite')) {
				params = {
					'p_tpl_uuid': $(e.target).parents('.panel').attr('data-uuid'),
					'p_action': 'delete'
				};
				jqXHR = $.ajax({
					url: url,
					type: 'POST',
					data: $.toJson(params),
					contentType: 'application/json',
					async: true
				}).done(function () {
					llprod.notificationRowHandler('success', 'appbuilder_wizard_favorites_success_delete');
					$(e.target).removeClass('font-icon-star').addClass('font-icon-star_outline');
					$(e.target).parents('.panel').attr('data-favorite', '');
				});
			} else {
				params = {
					'p_tpl_uuid': $(e.target).parents('.panel').attr('data-uuid'),
					'p_action': 'add'
				};
				jqXHR = $.ajax({
					url: url,
					type: 'POST',
					data: $.toJson(params),
					contentType: 'application/json',
					async: true
				}).done(function () {
					llprod.notificationRowHandler('success', 'appbuilder_wizard_favorites_success_add');
					$(e.target).removeClass('font-icon-star_outline').addClass('font-icon-star');
					$(e.target).parents('.panel').attr('data-favorite', '1');
				});
			}
		});
	}
};


llprod.menubarFormActions = function () {
	var form, fields, wrapper = $('.menubar-form-actions'), showAlways, ajaxRequest = wrapper.hasClass('ajax-request'), menubar = $('.menubar');
	if (wrapper.length) {
		$(wrapper).insertAfter(menubar);
	}
	if (!ajaxRequest) {
		form = wrapper.parents('form');
		if (form.length) {
			showAlways = wrapper.hasClass('show-always');
			fields = form.find(':input').not('#p_tpl_appdd').not('#foilautofill');
			if (showAlways) {
				wrapper.show();
			} else {
				var origForm = fields.serialize();
				form.on('change input', function () {
					if (fields.serialize() !== origForm) {
						wrapper.fadeIn('fast');
					} else {
						wrapper.fadeOut('fast');
					}
				});
			}
			wrapper.find('#submit').on('click', function () {
				form.submit();
			});
		}
	}
};


llprod.headOverlay = function () {
	var wrapper = $('.head-overlay'), header = $('#head');
	if (wrapper.length && header.length) {
		header.css('position', 'relative');
		$(wrapper).appendTo(header);
	}
};


llprod.notificationActivate = function () {
	var initiator = $('#p_notification');
	if (initiator.length) {
		var type = $(initiator).data('type');
		llprod.notificationRowHandler(type, initiator.val(), 5000);
	}
};


llprod.notificationRowHandler = function (type, message, time) {
	var menubarRow = $('.menubarRow');
	var notifyRow = $('.notify-row');
	type = type || 'success';
	time = time || 3500;

	if (type == 'success') {
		type = 'green';
	} else if (type == 'error') {
		type = 'red';
	} else if (type == 'info') {
		type = 'blue';
	} else if (type == 'message') {
		type = 'blue';
	}
	if (message.match(/^appbuilder/)) {
		message = llprod.globals.texts.__gettext__(message);
	}
	if (menubarRow.length && notifyRow.length) {
		$('.notify-row').remove();
		notifyRow = $('.notify-row');
	}
	if (menubarRow.length && notifyRow.length <= 0) {
		menubarRow.after(
			'<div class="notify-row ' + type + '">' +
			'<div class="message">' +
			message +
			'</div>' +
			'</div>');
		$('.notify-row').slideToggle('slow').delay(time).slideToggle('slow');
	}
};


llprod.fileuploadTemplate = function () {
	$('.fileupload-template').each(function () {
		var hiddenField = $(this).find('input[type=hidden]');
		var $this = $(this);
		$(this).fileupload({
			url: '/gateway/upload/cms',
			autoUpload: true,
			acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
			maxFileSize: 10000000,
			maxNumberOfFiles: 1,
			dropZone: $('.dropzone'),
			formData: {
				'uuid': $("#p_tpl_uuid").val(),
				'cl_id': $("#p_cl_id").val(),
			}
		}).on('fileuploadadd', function (e, data) {
			data.context = $('<div/>').appendTo($this.find('.files'));
			$.each(data.files, function (index, file) {
				var node = $('<div/>');
				node.appendTo(data.context);
			});
		}).on('fileuploaddone', function (e, data) {
			var r = $.parseJSON(data.result);
			var file = r.files[0];
			hiddenField.val(file.upl_id).trigger('change');

			if (!$this.hasClass('exists')) {
				$this.addClass('exists');
			}

			$.each(r.files, function (index, file) {
				if (file.url) {
					var img = $('<img>')
						// Use the non-cached `imgproxy` URL here, because the `imgproxycache` service
						// filters out cookies. We need the cookie to be passed through
						// to the `files` service, because the upload is still temporary and
						// as such will only by delivered to the user who uploaded it.
						.prop('src', '/imgproxy/insecure/rt:fill/w:155/h:155/el:1/g:sm/q:100/plain/' + file.url);
					$(data.context.children()[index])
						.append(img);
				} else if (file.error) {
					var error = $('<span class="text-danger"/>').text(file.error);
					$(data.context.children()[index])
						.append('<br>')
						.append(error);
				}
			});

			if ($('.mylivingapps-publish').length) {
				var publishPanel = $('.mylivingapps-publish');
				var publishImageWrapper = $this.find('.image-wrapper');
				var publishDownloadImg = $this.find('.files img');

				if (publishImageWrapper.length && publishDownloadImg.length) {
					publishImageWrapper.find('img').remove();
					publishDownloadImg.prependTo(publishImageWrapper);
				}
			} else if ($('.mylivingapps-detail').length) {
				var url, jqXHR, params;
				var detailPanel = $('.mylivingapps-detail');
				var detailImageWrapper = $this.find('.image-wrapper');
				var detailDownloadImg = $this.find('.files img');

				if (detailImageWrapper.length && detailDownloadImg.length) {
					$('.app-icon--detail').css('background-image', 'url(' + detailDownloadImg[0].src + ')');
					detailDownloadImg.remove();
				}

				url = AjaxURLManager.getUrlWithKey('ajaxdetailitem');
				url += '/' + $('.mylivingapps-detail-app').attr('data-id') + '.htm';
				params = {
					'formFieldContentType': 'application/json',
					'formKey': $('.mylivingapps-detail-app').attr('data-id'),
					'p_tpl_id': $('.mylivingapps-detail-app').attr('data-id'),
					'p_tpl_appdd': $("#p_tpl_appdd").val(),
					'p_icon_list_upload_id': $("#p_icon_list_upload_id").val(),
					'cms.apps.input.SUBMIT': 'true',
				};
				jqXHR = $.post(url, params)
					.done(function (data, textStatus, jqXHR) {
						self.data = data;
						if ($(".successRow") && data) {
							$(".successRow").appendTo('body').slideToggle('slow').delay(3500).slideToggle('slow');
						} else if ($(".errorRow").length) {
							$(".errorRow").appendTo('body').slideToggle('slow').delay(3500).slideToggle('slow');
						}
					});
			}
		}).on('fileuploadfail', function (e, data) {
			$.each(data.files, function (index) {
				var error = $('<span class="text-danger"/>').text('File upload failed.');
				$(data.context.children()[index])
					.append('<br>')
					.append(error);
			});
		});
		$(this).find('.fileinput-delete').on("click", function () {
			hiddenField.val('').trigger('change');
			$(this).parents('.fileinput').remove();
		});
	});
};


llprod.fileuploadUser = function () {
	$('.fileupload-avatar').each(function () {
		var hiddenField = $(this).find('input[type=hidden]');
		var $this = $(this);
		$(this).fileupload({
			url: '/gateway/upload/avatar',
			autoUpload: true,
			acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
			maxFileSize: 10000000,
			maxNumberOfFiles: 1,
			dropZone: $('.dropzone'),
			xhrFields: {
				withCredentials: true
			},
			formData: {
			}
		}).on('fileuploadsend', function (e, data) {
			$.blockUI({ message: '<img class="loading" src="/formbuilder/img/svg/loading-bubbles.svg" />' });
		}).on('fileuploadadd', function (e, data) {
			data.context = $('<div/>').appendTo($this.find('.files'));
			$.each(data.files, function (index, file) {
				var node = $('<div/>');
				node.appendTo(data.context);
			});
		}).on('fileuploaddone', function (e, data) {
			$.unblockUI();
			var r = data.result;
			var file = r.files[2];
			hiddenField.attr('data-upload-id', file.upl_id).trigger('change');

			if (!$this.hasClass('exists')) {
				$this.addClass('exists');
			}

			$.each(r.files, function (index, file) {
				if (file.url) {
					var img = $('<img>')
						// We can use `imgproxycache` here, since the image has already been made
						// public, so we don't have to pass along cookies for the logged in user.
						.prop('src', '/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/plain/' + file.url);
					$(data.context.children()[index])
						.append(img);
				} else if (file.error) {
					var error = $('<span class="text-danger"/>').text(file.error);
					$(data.context.children()[index])
						.append('<br>')
						.append(error);
				}
			});

			if ($('.profile').length) {
				var publishPanel = $('.profile');
				var publishImageWrapper = $this.find('.image-wrapper');
				var publishDownloadImg = $this.find('.files img');

				if (publishImageWrapper.length && publishDownloadImg.length) {
					publishImageWrapper.find('img').remove();
					publishDownloadImg.prependTo(publishImageWrapper);
					publishImageWrapper.find('img').equalizeDimensions({ calculate: 'height' });
				}
			}
		}).on('fileuploadfail', function (e, data) {
			$.unblockUI();
			$.each(data.files, function (index) {
				var error = $('<span class="text-danger"/>').text('File upload failed.');
				$(data.context.children()[index])
					.append('<br>')
					.append(error);
			});
		});
		$(this).find('.fileinput-delete').on("click", function () {
			hiddenField.val('1').trigger('change');
			$(this).parents('.fileinput').remove();
		});
	});
};


llprod.dropzoneHandler = function () {
	$(document).bind('dragover', function (e) {
		var dropZone = $('.dropzone'),
			foundDropzone,
			timeout = window.dropZoneTimeout;
		if (!timeout) {
			dropZone.addClass('in');
		}
		else {
			clearTimeout(timeout);
		}
		var found = false,
			node = e.target;

		do {

			if ($(node).hasClass('dropzone')) {
				found = true;
				foundDropzone = $(node);
				break;
			}

			node = node.parentNode;

		} while (node != null);

		dropZone.removeClass('in hover');

		if (found) {
			foundDropzone.addClass('hover');
		}

		window.dropZoneTimeout = setTimeout(function () {
			window.dropZoneTimeout = null;
			dropZone.removeClass('in hover');
		}, 100);
	});
};


llprod.navNoLinkHandler = function () {
	var href = $('#flnav_nolink');
	var noLink = $('.flOuterShell .noLink');
	var noLinkInner = $('.flOuterShell .noLink .inner');

	if (href.length && noLink.length) {
		href = href.val();

		noLink.addClass('notHere');
		noLinkInner.wrap('<a href="' + href + '"></a>');
	}
};


llprod.appDemoModeRequest = function () {
	var wrapper = $('.head-overlay-demo');

	if (wrapper.length) {
		var submit = $('.head-overlay-demo-submit'), cancel = $('.head-overlay-demo-cancel'), url, params;
		url = AjaxURLManager.getUrlWithKey('ajaxdemo');
		url += '/' + $('#p_tpl_id').val() + '.htm';

		submit.unbind('click').click(function (e) {
			e.preventDefault();
			params = {
				'formFieldContentType': 'application/json',
				'formKey': $('#p_tpl_id').val(),
				'p_tpl_uuid': $('#p_tpl_uuid').val(),
				'p_action': 'demoFinish',
				'p_deleteData': $('#p_deleteData').prop("checked", true).next().hasClass('checked'),
				'p_connectApp': 'false',
				'cms.apps.input.SUBMIT': 'true',
			};
			request();
		});

		cancel.unbind('click').click(function (e) {
			e.preventDefault();
			params = {
				'formFieldContentType': 'application/json',
				'formKey': $('#p_tpl_id').val(),
				'p_tpl_uuid': $('#p_tpl_uuid').val(),
				'p_action': 'demoCancel',
				'cms.apps.input.SUBMIT': 'true',
			};
			request();
		});

		var request = function () {
			$.blockUI({ message: '<img class="loading" src="/formbuilder/img/svg/loading-bubbles.svg" />' });
			jqXHR = $.ajax({
				"url": url,
				"type": 'POST',
				"data": params,
				"async": false,
				"success": function (data, textStatus, jqXHR) {
					window.location = data.redirect;
				}
			});
		};
	}
};

$.extend({
	lang: function (name, lang) {
		var i, j, k0, k1, resObj, res, csvParts, realCsvParts, csvStr = '', outStr = '', langs = [], tmpStr;
		if (arguments.length > 1) {
			if (name === '__csv__') {
				if (typeof lang === 'object') {
					for (i = 1; i < lang.length; i++) {
						csvParts = lang[i].split(';');
						realCsvParts = [];
						tmpStr = '';
						for (j = 0; j < csvParts.length; j++) {
							if (csvParts[j].match(/\"/)) {
								if (csvParts[j].match(/\"/g).length % 2 === 0) {
									realCsvParts.push(csvParts[j]);
								} else {
									tmpStr += tmpStr ? ';' + csvParts[j] : csvParts[j];
									if (tmpStr.match(/\"/g).length % 2 === 0) {
										if (tmpStr.search(/\"/) === 0) {
											tmpStr = tmpStr.substring(1);
											tmpStr = tmpStr.substring(0, tmpStr.length - 1);
										}
										realCsvParts.push(tmpStr);
										tmpStr = '';
									}
								}
							} else {
								realCsvParts.push(csvParts[j]);
							}
						}
						langs.push(realCsvParts);
					}
					for (i = 0; i < langs.length; i++) {
						outStr += '$.lang(\'';
						outStr += langs[i][0] + '\', {\n';
						for (j = 1; j < langs[i].length; j++) {
							if (j < langs[i].length - 1) {
								outStr += '    "' + lang[0].split(';')[j].match(/\([a-z]+\)/)[0].replace(/[\(\)]+/g, '') + '": ' + '\'' + langs[i][j] + '\'';
								outStr += ',\n';
							} else {
								outStr += '    "' + '__desc__' + '": ' + '\'' + langs[i][j] + '\'';
								outStr += '\n});\n';
							}
						}
					}
					return outStr;
				}
			} else {
				if (typeof lang === 'object') {
					if (!$.lang._res) {
						$.lang._res = {};
					}
					$.lang._res[name] = lang;
					return lang;
				} else {
					if ($.lang._res && $.lang._res[name] && $.lang._res[name][lang]) {
						return $.lang._res[name][lang];
					}
				}
			}
			return '?-invalid-language-resource-?';
		} else if (arguments.length === 1) {
			if (name === '__csv__') {
				for (k0 in $.lang._res) {
					resObj = $.lang._res[k0];
					csvStr += k0.replace(/;/g, '\\;') + ';';
					for (k1 in resObj) {
						res = resObj[k1];
						if ($.inArray(k1, langs) === -1) {
							langs.push(k1);
						}
						csvStr += '"' + (typeof res === 'object' ? + res.join(',') : res).replace(/"/g, '""').replace(/'/g, "''") + '";';
					}
					csvStr += '\n';
				}
				for (i = 0; i < langs.length; i++) {
					if (langs[i] === '__desc__') {
						outStr += 'Description (Dont edit!);';
					} else {
						outStr += 'Language-' + langs[i] + ';';
					}
				}
				outStr = 'Key (Dont edit!);' + outStr + '\n' + csvStr;
				return outStr;
			} else {
				if (typeof I18N_LANGUAGE !== 'undefined') {
					lang = I18N_LANGUAGE;
				} else {
					lang = $('html').attr('lang');
				}
				if (!lang) {
					lang = 'en';
				}
				if ($.lang._res && $.lang._res[name] && $.lang._res[name][lang]) {
					return $.lang._res[name][lang];
				}
				return '?-invalid-language-resource-?';
			}
		}
		if (typeof I18N_LANGUAGE !== 'undefined') {
			lang = I18N_LANGUAGE;
		} else {
			lang = $('html').attr('lang');
		}
		return lang;
	}
});

// > i18n js-formtool
$.lang('js_formtool_send_form_status_success', {
	"de": 'Die Daten wurden erfolgreich gesendet und gespeichert!<br/>' +
		'Vielen Dank für Ihre Mühe!',
	"en": '???',
	"__desc__": ''
});
$.lang('js_formtool_send_form_status_failed', {
	"de": 'Es sind Fehler bei der &Uuml;bermittlung der Daten aufgetreten!<br/>' +
		'Fehler: %s',
	"en": '???',
	"__desc__": ''
});
$.lang('js_formtool_notValidMsg_text', {
	"de": 'Bitte füllen Sie dieses Feld aus',
	"en": '???',
	"__desc__": ''
});
$.lang('js_formtool_error', {
	"de": 'Fehler',
	"en": 'Error',
	"__desc__": ''
});
// <

llprod.getUniqueKey = function () {
	return Math.floor(new Date().getTime() + 9999999 * Math.random(10000000));
};

llprod.timeoutObjects = {};

llprod.makeidentifier = function (name, names, prefix, suffix) {
	var replacements = {
		"Ä": "AE",
		"Ö": "OE",
		"Ü": "UE",
		"ä": "ae",
		"ö": "oe",
		"ü": "ue",
		"ß": "ss",
		"ÁĄȦÂẦẮẶÃḀĂǺÅẠẨẰÅĀȂǠẢẪẲǍẬÀǞȀẤẴ": "A",
		"ḆḂḄ": "B",
		"ČĊĈÇĆḈ": "C",
		"ḎḊḒĎḌḐ": "D",
		"ÉȆĔỆÊẾËĒḘĚȨỀḖẸĘḚỂẺȄĖḜỄÈḔẼ": "E",
		"Ḟ": "F",
		"ĜǴḠĢĠĞǦ": "G",
		"ĤḨȞḦḢḪḤ": "H",
		"ĬḬȈĪÌỈÍǏȊĨİḮÎỊÏĮ": "I",
		"Ĵ": "J",
		"ḰǨḲḴĶK": "K",
		"ḶĻḸḺḼĹĽ": "L",
		"ḾṀṂ": "M",
		"ṆÑŃṈṊŇǸṄŅ": "N",
		"ŌṎȎȮỎỖÒỞǬǑṐÓÔỐỘǪỠÕŐṒȪỒỚỢƠṌŎȌȰȬỌỔỜ": "O",
		"ṖṔ": "P",
		"ŔṞṘȐȒŘṚŖṜ": "R",
		"ŜṦȘŚṠṨŠṢŞṤ": "S",
		"ŤṮŢṰṪȚṬ": "T",
		"ǓÙǛŬṶȖÚỦỮǙÛŪŲṸỨỰǗŨŰṲṺƯỪǕŮṴȔỤỬ": "U",
		"ṾṼ": "V",
		"ŴẆẀẈẂẄ": "W",
		"ẊẌ": "X",
		"ỶẎỸÝŸȲỲŶỴ": "Y",
		"ŻŹẐẒŽẔ": "Z",
		"áǻấậẵâăǡãầắặāȧǟåạẩằḁȁȃảẫẳǎàą": "a",
		"ḅḇḃ": "b",
		"ċĉćḉçč": "c",
		"ḑḏďḍḋḓ": "d",
		"ễéẽȅêēḝěḕệḙëếḗęềẹȇėȩểẻèĕḛ": "e",
		"ḟ": "f",
		"ģġǧğḡǵĝ": "g",
		"ẖḥḩȟḧĥḣḫ": "h",
		"ȉīḭìḯĩỉíǐîȋįịïĭ": "i",
		"ǰĵ": "j",
		"ḵǩḱķḳ": "k",
		"ļḽĺḹḷľḻ": "l",
		"ḿṁṃ": "m",
		"ńñṅǹṇňṉņṋ": "n",
		"ọổờǫṍòȍȭỏỗởóơṏőǒôȱốộỡõŏṑȏồớợǭȯōȫṓ": "o",
		"ṕṗ": "p",
		"ṝṟřŗṙȑȓŕṛ": "r",
		"śṥṧšşṡṩșŝṣ": "s",
		"ţṭẗṯṱțťṫ": "t",
		"ụửùǔǜūṵųúȕủữûǚũṷűứựǘůṹȗưừǖŭṳṻ": "u",
		"ṽṿ": "v",
		"ẅẇẘẁẉẃŵ": "w",
		"ẍẋ": "x",
		"ỵỷẏýỹẙŷỳÿȳ": "y",
		"żẕźẑžẓ": "z"
	}, newname = [], testname, testsuffix = 2, chars, i, c,
		keywords = ["function", "if", "while", "for", "else", "elif", "elsif", "def", "yield", "from", "global", "True", "False", "None", "true", "false", "null"];
	for (chars in replacements) {
		name = name.replace(new RegExp("[" + chars + "]", "g"), replacements[chars]);
	}
	for (i = 0; i < name.length; ++i) {
		c = name[i];
		if ("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_".indexOf(c) >= 0) {
			newname.push(c);
		} else if ("0123456789".indexOf(c) >= 0) {
			if (!newname.length) {
				newname.push("_");
			}
			newname.push(c);
		} else if (newname.length && newname[newname.length - 1] != "_") {
			newname.push("_");
		}
	}
	while (newname.length && newname[newname.length - 1] == "_") {
		newname.pop();
	}
	newname = newname.join("") || "_";
	newname = (prefix || "") + newname + (suffix || "");
	testname = newname.toLowerCase();
	names = names || [];
	while (names.indexOf(testname) >= 0 || keywords.indexOf(testname) >= 0) {
		testname = newname.toLowerCase() + testsuffix;
		testsuffix += 1;
	}
	return testname;
};


$(window).load(function () {
	$('.mylivingapps-overview .panel').matchHeight();
	$('.catalog-overview .panel').matchHeight();
});

// Some starters
import autosize from 'autosize';

import getCookieNotice from './async';

import './llprod-globals';
import '../components/link-outer-element';

ll.$(function () {
	$.blockUI.defaults.css = {};

	// Select Embed Code
	$('.embed__code').click(function () { $(this).selectText(); });

	// Autosize FormElements
	autosize($('.mylivingapps-detail-app .description textarea'));

	// Match Heights
	$('.match-height').matchHeight(true);

	// Chars Left - WebApp release
	if ($('.mylivingapps-detail-app .headline input').length) {
		$('.mylivingapps-detail-app .headline input').limit('50', $('.headline-limit'));
	}
	if ($('.mylivingapps-detail-app .description textarea').length) {
		$('.mylivingapps-detail-app .description textarea').limit('1000', $('.description-limit'));
	}
	if ($('.p_name_limit_input').length) {
		$('.p_name_limit_input').limit('50', $('.p_name_limit'));
	}
	if ($('.p_description_limit_input').length) {
		$('.p_description_limit_input').limit('1000', $('.p_description_limit'));
	}

	// Workflow wizard
	AjaxURLManager.setMode('live');
	AjaxURLManager.setBaseURLStatic('/upload/02_apps-342-36/');
	AjaxURLManager.registerKey('workflow-wizard-proxy.htm', null, 'test_ww_static.json', false, '/');
	AjaxURLManager.registerKey('ajaxworkflowwizard', null, 'test_ww_static.json', false, '/ajaxproxy/');
	AjaxURLManager.registerKey('invalidate', null, null, false, '/gateway/');
	AjaxURLManager.registerKey('ajaxdetailitem', null, null, false, '/ajaxproxy/');
	AjaxURLManager.registerKey('ajaxdemo', null, null, false, '/ajaxproxy/');
	AjaxURLManager.registerKey('account', null, null, false, '/gateway/v1/');
	AjaxURLManager.registerKey('views', null, null, false, '/gateway/v2/appdd/');

	window.WW_PROXY_STD_PARAMS = {
		"get": {
			"formFieldContentType": 'application/json'
		},
		"post": {
			"cms.apps.input.SUBMIT": true,
			"formFieldContentType": 'application/json',
			"formKey": 1
		}
	};

	// Edit app properties on successpage.
	llprod.apppropertiesEdit();

	// Functions for popup used to copy an app.
	llprod.copyPopupHandler();

	// Handle internal fields table.
	llprod.dataManagementInternalFieldsHandling.init();

	// Handle cookie notice.
	// import getCookieNotice from './async';
	if (!($.cookie('cookieconsent_status'))) {
		getCookieNotice().then(component => {
			llprod.cookieNotice.init({
				"config": {
					"palette": null,
					"content": {
						"message": llprod.globals.texts.__gettext__('appbuilder_wizard_cookienotice_message'),
						"link": llprod.globals.texts.__gettext__('appbuilder_wizard_cookienotice_link_text'),
						"dismiss": llprod.globals.texts.__gettext__('appbuilder_wizard_cookienotice_dismiss'),
						"href": llprod.globals.texts.__gettext__('appbuilder_wizard_cookienotice_link_href')
					}
				}
			});
		});
	};

	// Submit SortWebApps Selectbox on change.
	llprod.mylivingappsSearch();
	llprod.catalogSearch();

	// Preview with magnific popup.
	llprod.catalogDetailPreview();

	// External Systems site.
	llprod.externalSystem();

	// Contextmenu.
	llprod.contextmenu();

	// Usermenu.
	llprod.usermenu();

	// Favorite Handler for LivingApps
	llprod.favoriteApp();

	// Submit Form with Menubar button
	llprod.menubarFormActions();

	// Move Head Overlay
	llprod.headOverlay();

	// If hiddenfield with id p_notification exists show notification
	llprod.notificationActivate();

	// Fileupload
	llprod.fileuploadTemplate();
	llprod.fileuploadUser();
	llprod.dropzoneHandler();

	// Nav Link (Link for no-link sites)
	llprod.navNoLinkHandler();

	// appDemoModeRequest
	llprod.appDemoModeRequest();

	// link the whole item
	llprod.LinkOuterElement.init({ 'target': $('#content').find('.apppanel-app-wrapper') });
	llprod.LinkOuterElement.init({ 'target': $('#content .mylivingapps-overview').find('.panel') });
	llprod.LinkOuterElement.init({ 'target': $('#content .catalog-overview').find('.panel') });
	llprod.LinkOuterElement.init({ 'target': $('#content').find('.outer-link') });

	// Same height
	$('.mylivingapps-publish-image img').equalizeDimensions({ calculate: 'height' });
	$('.app-icon--detail').equalizeDimensions({ calculate: 'height' });

	// Sticky Nav.
	$('.menubarRow').sticky(
		{
			topSpacing: 0,
			zIndex: 1000,
			wrapperClassName: 'menubar-sticky-wrapper'
		}
	);

	// Magnific Popup Menubar
	$('.menubar-mylivingapps-popup-iframe a').magnificPopup({
		type: 'iframe',
		removalDelay: 300,
		mainClass: 'mfp-fade'
	});

	// Magnific Popup Generic
	$('.js-open-link-as-popup').magnificPopup({
		type: 'iframe',
		removalDelay: 300,
		mainClass: 'mfp-fade'
	});

	// Resize iFrames with iFrame Resizer
	$('iframe.resize').iFrameResize({
		heightCalculationMethod: Utils.migBrowser.msie ? 'max' : 'lowestElement'
	});
});
